import moment from "moment";
moment.locale("pl-PL");
export default {
  filters: {
    dateFilter(value) {
      return getFormattedDate(value, "YYYY-MM-DD");
    },
    timeFilter(value) {
      return getFormattedDate(value, "HH:mm");
    },
    timeWithSecFilter(value) {
      return getFormattedDate(value, "HH:mm:ss");
    },
    dateTimeFilter(value) {
      return getFormattedDate(value, "YYYY-MM-DD HH:mm");
    },
    format(value, format) {
      return getFormattedDate(value, format);
    },
    support24(value) {
      return getRoundeTime(value);
    }
  }
};

function getFormattedDate(inputValue, format) {
  if (inputValue === undefined || !inputValue) return "Brak";
  var date = moment(inputValue);
  return date.isValid() ? date.format(format) : "Nieprawidłowa data";
}

function getRoundeTime(inputValue) {
  let empty = " ";
  let possibility1 = "00:00";
  let result1 = "24:00";
  let possibility2 = "00:00:00";
  let result2 = "24:00:00";

  if (inputValue === possibility1) return result1;
  if (inputValue === possibility2) return result2;
  if (inputValue.endsWith(empty + possibility1))
    return inputValue.replace(empty + possibility1, empty + result1);
  if (inputValue.endsWith(empty + possibility2))
    return inputValue.replace(empty + possibility2, empty + result2);

  return inputValue;
}
